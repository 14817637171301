import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React from 'react';
import { Container, Heading } from './styles';

export type SectionHeadingProps = {
  heading: string;
} & ComponentProps;

const SectionHeading: React.FC<SectionHeadingProps> = ({ heading, ...props }) => (
  <Container {...componentProps(props)}>
    <Grid alignLeft>
      <GridItem desktop={10} tabletLandscape={10}>
        <Heading>{heading}</Heading>
      </GridItem>
    </Grid>
  </Container>
);

export default SectionHeading;
