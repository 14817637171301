import { graphql, useStaticQuery } from 'gatsby';

type CsPetDetailsOptions = {
  csPetAboutYourPet: {
    about_your_pet_section: {
      pet_section_heading: string;
      add_pet_button_text: string;
      default_section_headings: {
        first_pet_section_default_text: string;
        second_pet_section_default_text: string;
        third_pet_section_default_text: string;
      };
      remove_button_texts: {
        remove_first_pet_button_text: string;
        remove_second_pet_button_text: string;
        remove_third_pet_button_text: string;
      };
    };
  };
};

export type PetDetailsOptions = {
  petSectionHeading: string;
  addPetButtonText: string;
  defaultSectionHeadings: string[];
  removePetButtonTexts: string[];
};

const query = graphql`
  query {
    csPetAboutYourPet {
      about_your_pet_section {
        pet_section_heading
        add_pet_button_text
        default_section_headings {
          first_pet_section_default_text
          second_pet_section_default_text
          third_pet_section_default_text
        }
        remove_button_texts {
          remove_first_pet_button_text
          remove_second_pet_button_text
          remove_third_pet_button_text
        }
      }
    }
  }
`;

export const usePetDetailsOptions = (): PetDetailsOptions => {
  const {
    pet_section_heading,
    add_pet_button_text,
    default_section_headings: {
      first_pet_section_default_text,
      second_pet_section_default_text,
      third_pet_section_default_text,
    },
    remove_button_texts: {
      remove_first_pet_button_text,
      remove_second_pet_button_text,
      remove_third_pet_button_text,
    },
  } = useStaticQuery<CsPetDetailsOptions>(query).csPetAboutYourPet.about_your_pet_section;
  return {
    petSectionHeading: pet_section_heading,
    addPetButtonText: add_pet_button_text,
    defaultSectionHeadings: [
      first_pet_section_default_text,
      second_pet_section_default_text,
      third_pet_section_default_text,
    ],
    removePetButtonTexts: [
      remove_first_pet_button_text,
      remove_second_pet_button_text,
      remove_third_pet_button_text,
    ],
  };
};
