import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import QuestionField from 'components/QuestionField';
import CoverStartDateQuestion from 'forms/AboutYourPolicyForm/CoverStartDateQuestion';

export const CoverStartSubheading = styled.p`
  font-weight: 400;
  ${fonts.headingSmall};
  margin-bottom: 0;
`;
export const StyledAgeQuestion = styled(QuestionField)`
  margin-top: ${spacing(4)};
  margin-bottom: ${spacing(4)};
`;

export const CoverDateDescription = styled.p`
  ${fonts.paragraphLarge};
  margin: 0;
`;

export const StyledCoverStartDateQuestion = styled(CoverStartDateQuestion)`
  margin-top: 0;
`;
