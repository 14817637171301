import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const Heading = styled.h2`
  margin: 0;

  ${fonts.headingMedium};
`;

export const Container = styled.div`
  margin: ${spacing(6)} 0 ${spacing(2)};
`;
