import Image from '@rsa-digital/evo-shared-components/components/Image';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import { Hero } from 'components/Header/HeroBanner/styles';

export const HeadingWithMargin = styled.h2`
  ${fonts.headingSmall};

  margin: ${spacing(4)} 0 ${spacing(1)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(2)};
  `}

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(4)};
  `}
`;

export const StyledImage = styled(Image)`
  height: ${spacing(4)};
  width: auto;
`;

export const StyledHero = styled(Hero)`
  padding-bottom: ${spacing(2)};
`;

export const Subheading = styled.p`
  ${fonts.paragraphLarge}
  margin: 0 0 ${spacing(2)};
`;
